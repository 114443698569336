<!-- eslint-disable max-len -->
<template>
    <div class="invention-proposal">
        <v-form
            ref="form"
            v-model="valid"
        >
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Proposal.title[0]') }}
                    </div>
                </div>
                <div class="form-content">
                    <div class="form-row">
                        <v-text-field
                            v-model="form.proposal.name"
                            outlined
                            :label="$t('InventionApply.Proposal.label[0]')"
                            placeholder=""
                            :hint="$t('InventionApply.Proposal.hint[0]')"
                            persistent-hint
                            :rules="twelveWordsRule"
                            class="border-orange-input form-input"
                        />
                    </div>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Proposal.title[1]') }}
                    </div>
                    <div class="tips">
                        {{ $t('InventionApply.Proposal.tips[0]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.level"
                        class="radio-group"
                        :rules="emptyRule"
                    >
                        <v-radio
                            value="大專校院組"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('InventionApply.Proposal.radio[0]') }}<span
                                        class="radio-tips"
                                    >{{ $t('InventionApply.Proposal.tips[1]') }}</span>
                                </div>
                            </template>
                        </v-radio>
                        <v-radio
                            value="高中職組"
                            color="#F97E2B"
                        >
                            <template v-slot:label>
                                <div class="radio-label">
                                    {{ $t('InventionApply.Proposal.radio[1]') }}<span class="radio-tips">{{ $t('InventionApply.Proposal.tips[2]') }}</span>
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Proposal.title[2]') }}
                    </div>
                    <div class="tips">
                        {{ $t('InventionApply.Proposal.tips[0]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-radio-group
                        v-model="form.proposal.main"
                        class="radio-group"
                        :rules="emptyRule"
                    >
                        <div class="radio-type">
                            <div class="label">
                                {{ $t('InventionApply.Proposal.label[1]') }}
                            </div>
                            <div class="block">
                                <v-radio
                                    value="慈善 1.以賑災為主，研發備災，賑災所需相關產品。"
                                    color="#F97E2B"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            {{ $t('InventionApply.Proposal.radio[2]') }}
                                        </div>
                                    </template>
                                </v-radio>
                                <v-radio
                                    value="慈善 2.以人文關懷為主，研發可以改善弱勢或偏鄉地區之民生或日常問題相關產品。"
                                    color="#F97E2B"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            {{ $t('InventionApply.Proposal.radio[3]') }}
                                        </div>
                                    </template>
                                </v-radio>
                            </div>
                        </div>
                        <div class="type">
                            <div class="label">
                                {{ $t('InventionApply.Proposal.label[2]') }}
                            </div>
                            <div class="block">
                                <v-radio
                                    value="醫療 1.以長照2.0為主，研發可幫助解決銀髮族或失能之身心障礙者的生理健康或日常照護之產品。"
                                    color="#F97E2B"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            {{ $t('InventionApply.Proposal.radio[4]') }}
                                        </div>
                                    </template>
                                </v-radio>
                                <v-radio
                                    value="醫療 2.研發可以改善醫療職業環境，提升病人安全及居家照者之產品。"
                                    color="#F97E2B"
                                >
                                    <template v-slot:label>
                                        <div class="radio-label">
                                            {{ $t('InventionApply.Proposal.radio[5]') }}
                                        </div>
                                    </template>
                                </v-radio>
                            </div>
                        </div>
                    </v-radio-group>
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Proposal.title[3]') }}
                    </div>
                    <div class="tips">
                        {{ $t('InventionApply.Proposal.tips[3]') }}
                    </div>
                    <v-checkbox
                        v-model="form.proposal.subs"
                        style="pointer-events: none;"
                        class="checkbox checkbox-title"
                        :rules="subsRule"
                    />
                </div>
                <div class="form-content">
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[3]')"
                        value="SDGs1"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[4]')"
                        value="SDGs2"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[5]')"
                        value="SDGs3"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[6]')"
                        value="SDGs4"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[7]')"
                        value="SDGs5"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[8]')"
                        value="SDGs6"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[9]')"
                        value="SDGs7"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[10]')"
                        value="SDGs8"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[11]')"
                        value="SDGs9"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[12]')"
                        value="SDGs10"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[13]')"
                        value="SDGs11"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[14]')"
                        value="SDGs12"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[15]')"
                        value="SDGs13"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[16]')"
                        value="SDGs14"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[17]')"
                        value="SDGs15"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[18]')"
                        value="SDGs16"
                        color="#F97E2B"
                        class="checkbox"
                    />
                    <v-checkbox
                        v-model="form.proposal.subs"
                        :label="$t('InventionApply.Proposal.label[19]')"
                        value="SDGs17"
                        color="#F97E2B"
                        class="checkbox"
                    />
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Proposal.title[4]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-textarea
                        v-model="form.proposal.abstract"
                        rows="10"
                        counter="300"
                        :label="$t('InventionApply.Proposal.label[20]')"
                        no-resize
                        :rules="abstractRule"
                        outlined
                        placeholder=""
                        :hint="$t('InventionApply.Proposal.hint[0]')"
                        persistent-hint
                    />
                </div>
            </div>
            <div class="apply-form-block">
                <div class="left-block">
                    <div class="block-title">
                        {{ $t('InventionApply.Proposal.title[5]') }}
                    </div>
                </div>
                <div class="form-content">
                    <v-file-input
                        v-model="tmpPdf"
                        accept=".pdf"
                        :label="$t('InventionApply.Proposal.label[21]')"
                        outlined
                        placeholder=""
                        :hint="$t('InventionApply.Proposal.hint[1]')"
                        persistent-hint
                        :rules="[pdfRule()]"
                        class="border-orange-input form-input"
                        @change="(file) =>
                            handlePdfChange(`form.proposal.pdfFile`, file, form.proposal.tmpPdf)"
                    />
                    <div
                        v-if="form.proposal.pdfFile"
                        class="preivew"
                    >
                        <a
                            :href="$static(form.proposal.pdfFile)"
                            target="_blank"
                            class="preview-link"
                        >{{ $t('InventionApply.Proposal.link') }}</a>
                    </div>
                </div>
            </div>
        </v-form>
        <div class="apply-form-block">
            <div class="left-block" />
            <div class="form-content">
                <div class="actions">
                    <div
                        class="btn white-btn"
                        @click="prevStep"
                    >
                        {{ $t('InventionApply.Proposal.button[0]') }}
                    </div>
                    <div
                        class="btn blue-btn"
                        @click="save"
                    >
                        {{ $t('InventionApply.Proposal.button[1]') }}
                    </div>
                    <div
                        class="btn orange-btn"
                        @click="nextStep"
                    >
                        {{ $t('InventionApply.Proposal.button[2]') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapMutations, mapActions } from 'vuex';

export default {
    components: {},
    data() {
        return {
            valid: false,
            tmpPdf: null,
            // rules
            emptyRule: [(v) => !!v || this.$t('InventionApply.Proposal.rule[0]')],
            abstractRule: [(v) => !!v || this.$t('InventionApply.Proposal.rule[0]'), (v) => v.length <= 300 || this.$t('InventionApply.Proposal.rule[1]')],
            twelveWordsRule: [
                (v) => !!v || this.$t('InventionApply.Proposal.rule[0]'),
                (v) => v.length <= 12 || this.$t('InventionApply.Proposal.rule[2]'),
            ],
            subsRule: [
                (v) => v.length >= 1 || this.$t('InventionApply.Proposal.rule[3]'),
            ],
            pdfRule() {
                if (this.form.proposal.pdfFile) {
                    return true;
                }
                return this.$t('InventionApply.Proposal.rule[4]');
            },
        };
    },
    computed: {
        ...mapState('mInventionApply', ['form']),
        applyId() {
            return this.$route.params.id;
        },
    },
    created() {
    },
    mounted() { },
    methods: {
        ...mapMutations('mNotification', ['handleError', 'setAlert']),
        ...mapActions('mInventionApply', ['saveProposal']),
        handlePdfChange(key, file, tmpKey) {
            if (!file) {
                _.set(this, key, '');
            }
            if (file) {
                if (file.size > 1024 * 1024 * 10) {
                    this.setAlert({
                        message: this.$t('alertMessage.data-10mb'),
                        color: 'error',
                    });
                    _.set(this, key, '');
                    _.set(this, tmpKey, '');
                    return;
                }
            }
            _.set(this, key, URL.createObjectURL(file));
        },
        prevStep() {
            this.$router.push({
                name: 'InventionTeamData',
                params: {
                    id: this.applyId,
                },
            });
        },
        save() {
            this.saveProposal({
                form: this.form.proposal,
                applyId: this.applyId,
            });
        },
        async nextStep() {
            this.$refs.form.validate();
            if (this.valid) {
                try {
                    await this.saveProposal({
                        form: this.form.proposal,
                        applyId: this.applyId,
                    });
                    this.$router.push({
                        name: 'InventionTerms',
                        params: {
                            id: this.applyId,
                        },
                    });
                } catch {
                    this.setAlert({
                        message: this.$t('alertMessage.data-no-write'),
                        color: 'error',
                    });
                }
            } else {
                this.setAlert({
                    message: this.$t('alertMessage.data-no-write'),
                    color: 'error',
                });
            }
        },
    },
};
</script>

<style lang="scss">
.checkbox-title .v-input--selection-controls__input{
    display: none !important;;
}
</style>
